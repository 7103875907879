
// const sections =  [
//   {
//     heading: 'Have your own AWS SES account?',
//     description: 'Send unlimited emails using your own AWS SES account.',
//     features: [
//       {
//         heading: 'Easy to setup',
//         description: 'Connect your AWS SES account with CampaignHQ in minutes.',
//       },
//       {
//         heading: 'Send unlimited emails',
//         description: 'Send unlimited emails using your own AWS SES account.',
//       },
//       {
//         heading: 'Multiple domains',
//         description: 'Send emails using multiple domains.',
//       },
//     ]
//   },
//   {
//     heading: 'Or use our Email service',
//     description: 'Use our email service to send emails.',
//     features: [
//       {
//         heading: 'Easy to setup',
//         description: 'Connect your AWS SES account with CampaignHQ in minutes.',
//       },
//       {
//         heading: 'Send unlimited emails',
//         description: 'Send unlimited emails using your own AWS SES account.',
//       },
//       {
//         heading: 'Multiple domains',
//         description: 'Send emails using multiple domains.',
//       },
//     ]
//   },{
//     heading: 'Connect WhatsApp cloud Api',
//     description: 'Connect your whatsapp cloud api to send unlimited whatsapp messages.',
//     features: [
//       {
//         heading: 'Easy to setup',
//         description: 'Connect your whatsapp cloud api with CampaignHQ in minutes.',
//       },
//       {
//         heading: 'Send unlimited whatsapp messages',
//         description: 'Send unlimited whatsapp messages using your own whatsapp cloud api.',
//       },
//       {
//         heading: 'Multiple domains',
//         description: 'Send whatsapp messages using multiple domains.',
//       },
//     ]
//   }
// ]


// export default function FeatureScroll() {
  
//   return (
//     <>
//     {
//       sections.map((section, index) => {
//         return (index % 2 === 0 ? <ImageLeftSided section={section} key={index}/> : <ImageRightSided section={section} key={index}/>)
//       })
//     }
//     </>
//   )
// }


// const ImageRightSided =  function ({section}) {
//   return (
//     <div className="overflow-hidden bg-white py-24 sm:py-32">
//       <div className="mx-auto max-w-7xl px-6 lg:px-8">
//         <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start">
//           <div className="lg:pr-4 lg:pt-4">
//             <div className="lg:max-w-lg">
//               {/* <h2 className="text-base font-semibold leading-7 text-indigo-600">Deploy faster</h2> */}
//               <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{section.heading}</p>
//               <p className="mt-6 text-lg leading-8 text-gray-600">
//                 {section.description}
//               </p>
//               <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
//                 {section.features.map((feature) => (
//                   <div key={feature.name} className="relative pl-9">
//                     <dt className="inline font-semibold text-gray-900">
//                       {/* <feature.icon className="absolute left-1 top-1 h-5 w-5 text-indigo-600" aria-hidden="true" /> */}
//                       {feature.heading}
//                     </dt>{' '}
//                     <dd className="inline">{feature.description}</dd>
//                   </div>
//                 ))}
//               </dl>
//             </div>
//           </div>
//           <img
//             src="https://tailwindui.com/img/component-images/dark-project-app-screenshot.png"
//             alt="Product screenshot"
//             className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:ml-0"
//             width={2432}
//             height={1442}
//           />
//         </div>
//       </div>
//     </div>
//   )
// }

// import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/20/solid'

// const features = [
//   {
//     name: 'Push to deploy.',
//     description:
//       'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.',
//     icon: CloudArrowUpIcon,
//   },
//   {
//     name: 'SSL certificates.',
//     description: 'Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo.',
//     icon: LockClosedIcon,
//   },
//   {
//     name: 'Database backups.',
//     description: 'Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus. Et magna sit morbi lobortis.',
//     icon: ServerIcon,
//   },
// ]

// const ImageLeftSided =  function ({section}) {
//   return (
//     <div className="overflow-hidden bg-white py-24 sm:py-32">
//       <div className="mx-auto max-w-7xl px-6 lg:px-8">
//         <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
//           <div className="lg:ml-auto lg:pl-4 lg:pt-4">
//             <div className="lg:max-w-lg">
//               <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{section.heading}</p>
//               <p className="mt-6 text-lg leading-8 text-gray-600">
//                 {section.description}
//               </p>
//               <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
//                 {section.features.map((feature) => (
//                   <div key={feature.name} className="relative pl-9">
//                     <dt className="inline font-semibold text-gray-900">
//                       {/* <feature.icon className="absolute left-1 top-1 h-5 w-5 text-indigo-600" aria-hidden="true" /> */}
//                       {feature.heading}
//                     </dt>{' '}
//                     <dd className="inline">{feature.description}</dd>
//                   </div>
//                 ))}
//               </dl>
//             </div>
//           </div>
//           <div className="flex items-start justify-end lg:order-first">
//             <img
//               src="https://tailwindui.com/img/component-images/dark-project-app-screenshot.png"
//               alt="Product screenshot"
//               className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
//               width={2432}
//               height={1442}
//             />
//           </div>
//         </div>
//       </div>
//     </div>
//   )
// }


import {
  BoltIcon,
  ChatBubbleBottomCenterTextIcon,
  GlobeAltIcon,
  ScaleIcon,
  HandThumbUpIcon,
  FaceSmileIcon,
  ArrowTrendingUpIcon
} from '@heroicons/react/24/outline'
import AwsIcon from '@/images/icons/aws-icon.png'
const transferFeatures = [
  {
    id: 1,
    name: 'Setup in minutes',
    description:
      'With our easy to use interface you can setup your account in minutes.',
    icon: BoltIcon,
  },
  {
    id: 2,
    name: 'No cap on emails',
    description:
      'Send unlimited emails using your own AWS SES account.',
    icon: HandThumbUpIcon,
  },
  {
    id: 3,
    name: 'Multiple domains',
    description:
      'Use as many domains as you want to send emails.',
    icon: GlobeAltIcon,
  },
]
const communicationFeatures = [
  {
    id: 1,
    name: 'Better price',
    description:
      'We provide the most affordable email service in the market with high deliverability.',
    icon: FaceSmileIcon,
  },
  {
    id: 2,
    name: 'High deliverability',
    description: 'We provide the most affordable email service in the market with high deliverability.',
    icon: ArrowTrendingUpIcon,
  },
]
import { EnvelopeIcon } from '@heroicons/react/20/solid'

export default function Example() {
  return (
    <div className="overflow-hidden bg-gray-50 py-16 lg:py-24">
      <div className="relative mx-auto max-w-xl px-6 lg:max-w-7xl lg:px-8">
        <svg
          className="absolute left-full hidden -translate-x-1/2 -translate-y-1/4 transform lg:block"
          width={404}
          height={784}
          fill="none"
          viewBox="0 0 404 784"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width={404} height={784} fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)" />
        </svg>

        <div className="relative">
          <h2 className="text-center text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
            A flexible solution for your business
          </h2>
          <p className="mx-auto mt-4 max-w-3xl text-center text-xl text-gray-500">
            Connect with your AWS SES account or use our email service to send emails we have got you covered.
          </p>
        </div>

        <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:items-center lg:gap-8">
          <div className="relative">
            <h3 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">Want to use your AWS account ?</h3>
            <p className="mt-3 text-lg text-gray-500">
              We have got you covered, connect your AWS SES account with CampaignHQ in minutes.
            </p>

            <dl className="mt-10 space-y-10">
              {transferFeatures.map((item) => (
                <div key={item.id} className="relative">
                  <dt>
                    <div className="absolute flex h-12 w-12 items-center justify-center rounded-xl bg-indigo-500 text-white">
                      <item.icon className="h-8 w-8" aria-hidden="true" />
                    </div>
                    <p className="ml-16 text-lg font-medium leading-6 text-gray-900">{item.name}</p>
                  </dt>
                  <dd className="ml-16 mt-2 text-base text-gray-500">{item.description}</dd>
                </div>
              ))}
            </dl>
          </div>

          <div className="relative -mx-4 mt-10 lg:mt-0" aria-hidden="true">
            <svg
              className="absolute left-1/2 -translate-x-1/2 translate-y-16 transform lg:hidden"
              width={784}
              height={404}
              fill="none"
              viewBox="0 0 784 404"
            >
              <defs>
                <pattern
                  id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={784} height={404} fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)" />
            </svg>
            <img
              className="relative mx-auto"
              width={300}
              src={AwsIcon.src}
              alt=""
            />
          </div>
        </div>

        <svg
          className="absolute right-full hidden translate-x-1/2 translate-y-12 transform lg:block"
          width={404}
          height={784}
          fill="none"
          viewBox="0 0 404 784"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width={404} height={784} fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
        </svg>

        <div className="relative mt-12 sm:mt-16 lg:mt-24">
          <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:items-center lg:gap-8">
            <div className="lg:col-start-2">
              <h3 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
                Want to use our email service ?
              </h3>
              <p className="mt-3 text-lg text-gray-500">
                We have got you covered, we provide the most affordable email service in the market with high deliverability.
              </p>

              <dl className="mt-10 space-y-10">
                {communicationFeatures.map((item) => (
                  <div key={item.id} className="relative">
                    <dt>
                      <div className="absolute flex h-12 w-12 items-center justify-center rounded-xl bg-indigo-500 text-white">
                        <item.icon className="h-8 w-8" aria-hidden="true" />
                      </div>
                      <p className="ml-16 text-lg font-medium leading-6 text-gray-900">{item.name}</p>
                    </dt>
                    <dd className="ml-16 mt-2 text-base text-gray-500">{item.description}</dd>
                  </div>
                ))}
              </dl>
            </div>

            <div className="relative -mx-4 mt-10 lg:col-start-1 lg:mt-0">
              <svg
                className="absolute left-1/2 -translate-x-1/2 translate-y-16 transform lg:hidden"
                width={784}
                height={404}
                fill="none"
                viewBox="0 0 784 404"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="e80155a9-dfde-425a-b5ea-1f6fadd20131"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width={784} height={404} fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)" />
              </svg>
              <div  className="pt-6 max-w-[500px]">
                <div className="flow-root rounded-lg bg-gray-100 px-6 pb-8">
                  <div className="-mt-6">
                    <div>
                      <span className="inline-flex items-center justify-center rounded-xl p-3 bg-white shadow-lg">
                        <EnvelopeIcon className="h-10 w-10 text-green-500" aria-hidden="true" />
                      </span>
                    </div>
                    <h3 className="mt-8 text-lg font-semibold leading-8 tracking-tight text-gray-900">
                      Email service which you can trust
                    </h3>
                    <p className="mt-5 text-base leading-7 text-gray-600">
                      We provide the most affordable email service in the market with high deliverability.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
