
import { MegaphoneIcon, UserGroupIcon, CursorArrowRippleIcon, Cog6ToothIcon } from "@heroicons/react/24/outline"

import { WaIcon } from "../core/icons/WaIcon"
import { AwsWhiteIcon } from "@/components/core/icons/AwsWhiteIcon"

const features = [
  {
    name: 'Campaign Management',
    description:
      'Manage your campaigns, create new campaigns, and view your campaign analytics.',
    imageSrc: <MegaphoneIcon className="text-white w-12"/>,
  }, {
    name: 'Contact Management',
    description:
      'Manage your contacts, create new contacts, and view your contact analytics.',
    imageSrc: <UserGroupIcon className="text-white w-12"/>,
  }, {
    name: 'Drag & Drop Email Builder',
    description:
      'Create beautiful emails with our drag and drop email builder.',
    imageSrc: <CursorArrowRippleIcon className="text-white w-12"/>,
  }, {
    name: 'Automations',
    description:
      'Create automations to send emails and WhatsApp messages automatically.',
    imageSrc: <Cog6ToothIcon className="text-white w-12"/>,
  }, {
    name: 'Aws SES Integration',
    description:
      'Send emails using your own AWS SES account.',
    imageSrc: <AwsWhiteIcon className="text-white w-12"/>,
  }, {
    name: 'WhatsApp Integration',
    description:
      'Send WhatsApp messages through business API or our own WhatsApp gateway.',
    imageSrc: <WaIcon className="text-white w-12"/>,
  }
]




const Item = ({ name, description, imageSrc }) => {
  return (
    <div className="bg-white/5 p-8 sm:p-10 text-left">
      {imageSrc}
      <div className="mt-6">
        <h3 className="text-lg font-medium text-white">{name}</h3>
        <p className="mt-2 text-base text-white">{description}</p>
      </div>
    </div>
  )
}

export default function Features() {
    
  return (
    <div className="bg-gray-900 pt-12 pb-24 sm:py-32">
      <h2 className="text-3xl font-extrabold text-white text-center mb-12">
        All-in-one platform for your marketing needs
      </h2>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="-mx-6 grid grid-cols-1 gap-0.5 overflow-hidden sm:mx-0 sm:rounded-2xl md:grid-cols-3">
          {features.map((item) => (
            <Item
              key={item.name}
              name={item.name}
              description={item.description}
              imageSrc={item.imageSrc}
            />
          ))}
        </div>
      </div>
    </div>
  )
}
