import AwsWhiteLogo from '@/images/logos/test.svg'
import Image from 'next/image'
import { ReactSVG } from 'react-svg'


export const AwsWhiteIcon = function ({ name, className, ...props }) {
  return (
    <Image src={AwsWhiteLogo.src} alt="AWS" width={60} height={100} />
  )
}


